<template lang="pug">
div.view_container
  Header
  div.view_title  Sign Up
  button.google_signin(v-on:click="googleSignIn" v-if="showGoogleSignIn") Sign Up with Google
  div.or(v-if="showGoogleSignIn") Or with email & password
  div.input_container
    input(v-model="firstName" type="text" placeholder="First name" v-bind:class="{highlight: highlightFieldFirstName}")
  div.input_container
    input(v-model="lastName" type="text" placeholder="Last name" v-bind:class="{highlight: highlightFieldLastName}")
  div.input_container
    input(v-model="email" type="text" placeholder="Enter email" v-bind:class="{highlight: highlightFieldEmail}")
  div.input_container
    input(v-model="password" type="password" placeholder="Enter password" v-on:keyup="keyEnterToSubmit" v-bind:class="{highlight: highlightFieldPassword}")
  div.legal_text
    span By signing up you agree to our &nbsp;
    router-link.legal_link(style="touch-action: manipulation" to="terms") terms of service
    span &nbsp;and &nbsp;
    router-link.legal_link(style="touch-action: manipulation" to="privacy") privacy policy
  button(v-on:click="signUp" v-bind:class="{cta_secondary: showGoogleSignIn, cta:!showGoogleSignIn}") Sign Up
</template>

<script>
/* global gtag */
import { SIGNUP_MUTATION, SIGNIN_WITH_CODE_MUTATION } from '../graphql/mutations'
import { ME_QUERY } from '../graphql/queries'

import Header from '../components/Header'

import { isEmail } from '../lib/utils'

export default {
  name: 'SignUp',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    referral () {
      return this.$route.query.referral
    },
    highlightFieldFirstName () {
      return this.highlightField == "first"
    },
    highlightFieldLastName () {
      return this.highlightField == "last"
    },
    highlightFieldPassword () {
      return this.highlightField == "pass"
    },
    highlightFieldEmail () {
      return this.highlightField == "email"
    },
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      highlightField: null,
      showGoogleSignIn: process.env.VUE_APP_SHOW_GOOGLE_SIGNIN,
    }
  },
  apollo: {

  },
  methods: {
    signinWithCode: function(authCode) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SIGNIN_WITH_CODE_MUTATION,
        variables: {
          code: authCode,
          referral: this.referral,
        }
      }).then((data) => {
        console.log(data)
        const user = data.data.signInWithCode.user
        const token = data.data.signInWithCode.token
        const isNew = data.data.signInWithCode.isNew
        const errors = data.data.signInWithCode.errors

        if (token) {
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
        }
        if (token && user) {
          gtag('event','login')
          if (isNew) {
            this.$router.push({name:"CreateOrUpdateProject"})
          } else {
            this.$router.push({name:"Home"})
          }
        }

        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    googleSignIn: async function () {
      // const googleUser = await this.$gAuth.signIn()
      // console.log(googleUser)
      // window.user = googleUser
      const authCode = await this.$gAuth.getAuthCode()
      // console.log(authCode)
      this.signinWithCode(authCode)
      // The authCode that is being returned is the one-time code that you can send to your backend server, so that the server can exchange for its own access_token and refresh_token.
      // const userId = googleUser.getId()
      // const profile = googleUser.getBasicProfile()
      // const response = googleUser.getAuthResponse()
      // const token = response.access_token

    },
    signUp: function() {
      this.highlightField = null
      if (!this.firstName) {
        this.$toast.error("Please type in a first name")
        this.highlightField = "first"
        return
      } else if (!this.lastName) {
        this.$toast.error("Please type in a last name")
        this.highlightField = "last"
        return
      } else if (!this.email || !isEmail(this.email)) {
        this.$toast.error("Please type in a valid email")
        this.highlightField = "email"
        return
      } else if (!this.password) {
        this.$toast.error("Please type in a password")
        this.highlightField = "pass"
        return
      }

      this.$apollo.mutate({
        mutation: SIGNUP_MUTATION,
        variables: {
          input: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            referral: this.referral,
          }
        }
      }).then((data) => {
        var user = data.data.signUp.user
        var token = data.data.signUp.token
        var errors = data.data.signUp.errors
        this.errorsToast(errors)

        if (token) {
          // localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_KEY, token)
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
          this.$router.push({name:"CreateOrUpdateProject"})
        }

      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    getMe: function() {
      this.$apollo.query({
        query: ME_QUERY,
        variables: {
        }
      }).then((data) => {
        console.log(data.data)
      }).catch((error) => {
        console.error(error)
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.signUp()
      }
    }
  },
  mounted: function () {
    // window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/signup'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.legal_text {
  margin: 12px 8px 36px 8px;
  font-family: gibsonregular;
  font-size: 18px;
  color: #ADAAB6;
}
.legal_link {
  color: #ADAAB6;
  text-decoration: none;
  font-weight: bold;
}
.legal_link:hover {
  text-decoration: underline;
  color: #6e6d7a;
  text-align:  center;
}
.or {
  margin: 30px 12px 20px 12px;
  border: none;
  font-family: gibsonregular;
  font-size: 20px;
}

.highlight {
  background-color: #ffe7b6;
}

</style>
