export const clearSelection = function() {
  if (window.getSelection) { window.getSelection().removeAllRanges() }
  else if (document.selection) { document.selection.empty() }
}

export const formatDate = function(isoString, full=true) {
  const date = new Date(isoString)
  const monthString = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  // var hours = date.getHours();
  // var minutes = date.getMinutes();
  // var ampm = hours >= 12 ? 'pm' : 'am';
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  // return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime
  if (full) return (monthString[date.getMonth()]) + " " + date.getDate() + ", " + date.getFullYear()

  return (monthString[date.getMonth()]) + " " + date.getDate()
}

export const missingUserInfo = function(user) {
  if (!user.firstName || user.firstName == "" ||
    !user.lastName || user.lastName == ""
    ) {
      return true
    }
    return false
}

export const isEmail = function(text) {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}$/.test(text)) return true
  return false
}
